import React from 'react';
import PropTypes from 'prop-types';
import {openBeacon} from 'helpers/commonHelper';

const ErrorPage = ({urls, brand, bgImage, year, translations} ) => {
  const {host, company: companyUrl, contact: contactUrl} = urls;
  const {
    pageNotFound,
    bodyEmail1,
    bodyEmail2,
    goToHomepage,
    homepage,
    company,
    contact
  } = translations;

  return (
    <section className="position-relative">
      <img className="position-absolute w-1-1 h-1-1 img-cover" src={bgImage} />
      <div className="container position-relative h-1-1 w-lg-1-2 py-5">
        <div className="mb-4 p-3 text-center bg-overlay text-white">
          <h1 className="display-3">{pageNotFound}</h1>
          <div className="mb-3">
            {bodyEmail1} <br /> <br />
            <a href={host} className="btn btn-primary" role="button">
              {goToHomepage}
            </a>
          </div>
          <p>{bodyEmail2}</p>
          <div>
            <a href={host} className="mr-3">{homepage}</a>
            <a href={companyUrl} className="mr-3">{company}</a>
            {
              contactUrl ?
                <a href={contactUrl} target="_blank" rel="noopener noreferrer">{contact}</a>:
                <a onClick={openBeacon}>{contact}</a>
            }
          </div>
        </div>
        <div className="text-center text-white">
          <small>Copyright &copy; {year} - <span id="date"/> {brand}<sup>&reg;</sup></small>
        </div>
      </div>
    </section>
  );
};

ErrorPage.propTypes = {
  brand: PropTypes.string.isRequired,
  bgImage: PropTypes.object.isRequired,
  urls: PropTypes.shape({
    host: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired
  }),
  year: PropTypes.number.isRequired,
  translations: PropTypes.shape({
    pageNotFound: PropTypes.string.isRequired,
    bodyEmail1: PropTypes.string.isRequired,
    bodyEmail2: PropTypes.string.isRequired,
    goToHomepage: PropTypes.string.isRequired,
    homepage: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired
  })
};

export default ErrorPage;
