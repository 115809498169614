import {getCompanyPath} from 'helpers/commonHelper';
import {replaceAssetsImgUrl, calcBackgroundFullWidth} from 'cms/helpers/common';


export default function notFoundStore({brand, market, website, isAirtickets, contactForm, bgImages, translations}) {
  const {
    pageNotFoundAT,
    pageNotFoundTP,
    ATBodyEmail1,
    TPBodyEmail1,
    ATBodyEmail2,
    TPBodyEmail2,
    goToHomepage,
    homepage,
    company,
    contact
  } = translations;
  const companyPath = getCompanyPath(brand, market);
  const host = isAirtickets ? website : '/';
  const {notFoundImageAT, notFoundImageTP} = bgImages;
  return {
    brand,
    urls: {
      host,
      company: `${host}${isAirtickets ? '/' : ''}${companyPath}`,
      contact: contactForm()
    },
    bgImage: replaceAssetsImgUrl(isAirtickets ? notFoundImageAT : notFoundImageTP, calcBackgroundFullWidth()),
    year: new Date().getFullYear(),
    translations: {
      pageNotFound: isAirtickets ? pageNotFoundAT : pageNotFoundTP,
      bodyEmail1: isAirtickets ? ATBodyEmail1 : TPBodyEmail1,
      bodyEmail2: isAirtickets ? ATBodyEmail2 : TPBodyEmail2,
      goToHomepage,
      homepage,
      contact,
      company
    }
  };
}
