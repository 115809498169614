import React from 'react';
import {render} from 'react-dom';
import Navbar from 'components/navbar/Navbar';
import ErrorPage from 'components/error/ErrorPage';
import Footer from 'components/footer';
import navbarStore from 'components/navbar/NavbarStore';
import footerStore from 'components/footer/FooterStore';
import notFoundStore from 'components/error/store';
import {
  brand,
  website,
  isAirtickets,
  market,
  contactForm
} from 'helpers/stableValues';
import Translation from 'lib/helpers/translation';
import notFoundImageAT from 'assets/images/error/notFoundImageAT.jpg';
import notFoundImageTP from 'assets/images/error/notFoundImageTP.jpg';

const translations = {
  pageNotFoundTP: Translation.tr('pageNotFoundTP', 'notFound'),
  pageNotFoundAT: Translation.tr('pageNotFoundAT', 'notFound'),
  TPBodyEmail1: Translation.tr('TPBodyEmail1', 'notFound'),
  ATBodyEmail1: Translation.tr('ATBodyEmail1', 'notFound'),
  TPBodyEmail2: Translation.tr('TPBodyEmail2', 'notFound'),
  ATBodyEmail2: Translation.tr('ATBodyEmail2', 'notFound'),
  goToHomepage: Translation.tr('goToHomepage', 'notFound'),
  homepage: Translation.tr('homepage', 'notFound'),
  contact: Translation.tr('contact', 'notFound'),
  company: Translation.tr('company', 'notFound')
};

const bgImages = {
  notFoundImageAT,
  notFoundImageTP
};

render(
  <>
    <Navbar navbar={navbarStore} />
    <ErrorPage {...notFoundStore({brand, market, website, isAirtickets, contactForm, bgImages, translations})} />
    <Footer store={footerStore} />
  </>
  , document.getElementById('notfound')
);
